import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: "cart",
    initialState : {
        items: []
    },
    reducers : {
        addItem: (state, action) => {
            //Mutating the state
            state.items.push(action.payload)
        },
        removeItem: (state) => {
            state.items.pop()
        },
        clearItems: (state) => {
            state.items.length = 0;
        }
    }
})

export const {addItem, removeItem, clearItems} = cartSlice.actions;

export default cartSlice.reducer;